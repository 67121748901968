import HttpService, { catchReporter, reporter } from "./httpService";
import { Login, IsLogin, Register, APIResponse } from "../interfaces/authModel";
import { ReferralAddress, RegisterForm } from "../types/registerTypes";
import toast from 'react-hot-toast';
import config from "../config";
import { toPascalCase } from "../utils/tools";
import { broadcastChannel } from "./BroadcastChannel";
import Cookies from 'js-cookie'

const { tokenKey } = config.service
export const getToken = (): string | null =>
    localStorage.getItem(config.service.tokenKey);

export function removeDidAcknowledgeCookie() {
    document.cookie = "aknowledge=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
}

export function updateDidAcknowledgeValue(newValue: any) {
    Cookies.set("aknowledge", newValue)
}

export function getDidAcknowledgeValue() {
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookie.split(';');

    for (let i = 0; i < cookieArray.length; i++) {
        const currentCookie = cookieArray[i].trim(); // Remove leading/trailing whitespace
        if (currentCookie.startsWith('aknowledge=')) {
            return currentCookie.substring('aknowledge='.length);
        }
    }

    return null; // If cookie is not found
}

function doesAcknowledgeCookieExist() {
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookie.split(';');

    for (let i = 0; i < cookieArray.length; i++) {
        const currentCookie = cookieArray[i].trim(); // Remove leading/trailing whitespace
        if (currentCookie.startsWith('aknowledge=')) {
            return true; // Cookie found
        }
    }

    return false; // Cookie not found
}


class AuthService {
    private _httpService = HttpService('Authentication');

    async login(user: string, password: string, token?: string) {
        try {
            let response;

            if (token && token?.length > 0) {
                response = await this._httpService.post<Login>('/Login', { token: token });
            } else {
                response = await this._httpService.post<Login>('/Login', { email_address: user, password });
            }


            if (response.data.status) {
                localStorage.setItem(tokenKey, response.data.token);
                const userObject = { email: response?.data?.info?.email_address, username: response?.data?.info?.username, phone: response?.data?.info?.phone_number }
                localStorage.setItem("user", JSON.stringify(userObject));
                return true;
            } else {
                reporter(response)
                throw new Error(response?.data?.error?.toString())
            }
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex)
        }
    }

    async login2FA(user: string, password: string, token: string) {
        try {
            const response = await this._httpService.post<Login>('/Login2fa', { email_address: user, password, token: token });
            if (response.data.status) {
                localStorage.setItem(tokenKey, response.data.token);
                const userObject = { email: response?.data?.info?.email_address, username: response?.data?.info?.username, phone: response?.data?.info?.phone_number }
                localStorage.setItem("user", JSON.stringify(userObject));
                return true;
            } else {
                reporter(response)
                throw new Error(response?.data?.error?.toString())
            }
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex)
        }
    }

    async isLogin(page?: string) {
        const currentToken = getToken();
        try {
            const response = (currentToken || page === 'login' || page === 'register') && await this._httpService.get<IsLogin>('/IsUserLogin');
            if (response && response.data.message === null) return true
            if (response && Boolean(response.data.message) === true) return true
            if (response && Boolean(response.data.message) === false) {
                localStorage.removeItem(tokenKey);
                
                return false
            }
            return true

        } catch (ex: any) {
            return true;
        }
    }
    async login2FAChecker(user: string, password: string) {
        try {
            const response = await this._httpService.post<any>('/CheckLogin2fa/', { email_address: user, password });
            if (response.data?.status) return response?.data?.fa
            else {
                reporter(response)
                return Promise.reject(new Error(response?.data?.error));
            }
        } catch (ex: any) {
            reporter(ex.response)
            return false;
        }
    }
    async passwordLinkChecker(hash: string) {
        try {
            const response = await this._httpService.get<IsLogin>('/PasswordLinkCheker/' + hash);
            if (response.data.status) return response?.data?.message
            else reporter(response)
        } catch (ex: any) {
            reporter(ex.response)
            return false;
        }
    }
    async checkParentToRegister(ref?: any) {
        try {
            const response = await this._httpService.get<any>('/CheckParent', { params: { referral_code: ref } });
            if (response.data.status) return response?.data?.message
            else throw new Error(response?.data?.error?.toString())
        } catch (ex: any) {
            reporter(ex.response)
            catchReporter(ex)
            throw new Error(ex)
        }
    }
    async checkUser(email: any) {
        try {
            const response = await this._httpService.get<any>('/GetMigratedEmail', { params: { email } });
            if (response.data.status) return response?.data?.message
            else throw new Error(response?.data?.error?.toString())
        } catch (ex: any) {
            reporter(ex.response)
            catchReporter(ex)
            throw new Error(ex)
        }
    }
    async checkUpline(email: any) {
        try {
            const response = await this._httpService.get<any>('/MigratedUserUpline', { params: { email } });
            if (response.data.status) return response?.data?.message
            else throw new Error(response?.data?.error?.toString())
        } catch (ex: any) {
            reporter(ex.response)
            catchReporter(ex)
            throw new Error(ex)
        }
    }
    async skipConnectorVerification() {
        try {
            const response = await this._httpService.post<any>('/MigratedUserUpdateUpline');
            if (response.data.status) return response?.data
            else throw new Error(response?.data?.error?.toString())
        } catch (ex: any) {
            reporter(ex.response)
            catchReporter(ex)
            throw new Error(ex)
        }
    }
    async isRefValid(referral: ReferralAddress) {
        try {
            const response = await this._httpService.post<Register>('/CheckRefValidation', referral);
            if (response.data.status) return true;
        } catch (ex: any) {
            catchReporter(ex)
            // backend: wrong status => expect 403, but we got 400!
            if ((ex.response && ex.response.status === 403) || (ex.response && ex.response.status === 400)) {
                toast.error(ex.response.data.error)
            }
            return false;
        }
    }

    async register(formData: RegisterForm) {
        try {
            const response = await this._httpService.post<Register>('/RegisterDeactive', formData);
            if (response.data.status) {
                return true;
            } else {
                if (typeof response.data.error === 'string') {
                    throw new Error(response?.data?.error?.toString())
                } else if (response.data.errors) {
                    response.data.errors.map(e => toast.error(`${toPascalCase(e.param)}: ${e.msg}`))
                    throw new Error()
                }
            }
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex)
        }
    }

    async resetPassword(email: string) {
        try {
            const response = await this._httpService.post<Register>('/ResetPassword', { email_address: email });
            if (response.data.status) {
                return true;
            } else {
                if (typeof response.data.error === 'string') {
                    throw new Error(response?.data?.error?.toString())
                } else if (response.data.errors) {
                    response.data.errors.map(e => toast.error(`${toPascalCase(e.param)}: ${e.msg}`))
                    throw new Error()
                }
            }
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex)
        }
    }

    async newPassword(password: string, token: string, key: string) {
        try {
            const response = await this._httpService.post<any>('/FinalResetPassword', {
                password: password,
                token: token,
                key: key,
            });
            if (response.data.status) {
                return true;
            } else {
                if (typeof response.data.error === 'string') {
                    throw new Error(response?.data?.error?.toString())
                } else if (response.data.errors) {
                    response.data.errors.map((e: any) => toast.error(`${toPascalCase(e.param)}: ${e.msg}`))
                    throw new Error()
                }
            }
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex)
        }
    }

    async logout() {
        await localStorage.clear();
        !doesAcknowledgeCookieExist() && removeDidAcknowledgeCookie()
        localStorage.setItem('version', String(config.version));
        broadcastChannel.postMessage({ type: 'logout' });
        return true;
    }

    async getWatchVideos() {
        try {
            const response = await this._httpService.get<any>('/TutorialVideo');
            if (response.data.status) return response?.data?.message?.[0]
            else throw new Error(response?.data?.error?.toString())
        } catch (ex: any) {
            reporter(ex.response)
            catchReporter(ex)
            throw new Error(ex)
        }
    }
}

export default new AuthService()