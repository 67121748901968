import { QRCodeSVG } from 'qrcode.react';
import { useContext, useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import toast from 'react-hot-toast';
import PrivateContext from '../../../context/private/privateContext';
import WizardContext from '../../../context/private/wizardContext';
import { Flex } from '../../../styles/Styles';
import wazardValidation from '../../../validations/wazardValidation';
import TooltipInfo from '../../TooltipInfo';
import DickPickerInputCryptoTransfer from './date-picker-crypto-transfer-date';
import DickPickerInputWireExp from './date-picker-wire-expected-date';
import DickPickerInputWireTransfer from './date-picker-wire-transfer-date';

import { RadioButton, RadioGroup } from 'react-radio-buttons';
import { isMatching } from '../../../utils/tools';
import WalletContext from '../../../context/private/walletContext';
import dictionary from '../../../locals/expiration.json';
import { MenuItem } from '../../MenuItem';

const Modal = ({ type = 'first-time' }) => {
  const {
    loadWalletInfoMembershipModal,
    walletInfoMembershipModal,
    paymentCheckUser,
    errorsWireFee,
    setErrorsWireFee,
    errorsCryptoFee,
    setErrorsCryptoFee,
    paymentInfo,
    isLockedBtnPayFee,
    loadPaymenInfo,
    loadPaymenCheckMigratedUser,
    datePickerWireTransfer,
    datePickerWireExp,
    modal,
    setModal,

    membershipFormDataCryptoRenew,
    setMembershipFormDataCryptoRenew,
    membershipFormDataWireRenew,
    setMembershipFormDataWireRenew,

    membershipFormDataCrypto,
    setMembershipFormDataCrypto,
    membershipFormDataWire,
    setMembershipFormDataWire,
    payFeeHandle,
    payFeeByBalanceHandle,
    payFeeRenewHandle,
    datePickerCryptoTransfer,
    membershipFormDataBosBalanceRenew,
    setMembershipFormDataBosBalanceRenew
  } = useContext(WizardContext);
  const [isCrypto, setIsCrypto] = useState(1); // 1, 2, 3

  const { settings, setUpdateTimer } = useContext(PrivateContext);

  const submitCryptoHandle = () => {
    payFeeHandle(membershipFormDataCrypto, {}, 'crypto');
  };

  const submitCryptoRenewHandle = () => {
    membershipFormDataCryptoRenew.transfer_date =
      membershipFormDataCryptoRenew?.transfer_date
        ? membershipFormDataCryptoRenew?.transfer_date
        : +new Date();

    payFeeRenewHandle(membershipFormDataCryptoRenew, {}, 'crypto');
  };

  const submitBOSHandle = () => {
    payFeeByBalanceHandle();
  };
  const submitBOSRenewHandle = () => {
    payFeeRenewHandle(membershipFormDataBosBalanceRenew, {}, 'crypto');
  };

  const submitWireHandle = () => {
    const formData = new FormData();
    formData.append('account_name', membershipFormDataWire.account_name);
    formData.append('account_number', membershipFormDataWire.account_number);

    formData.append('bank_name', membershipFormDataWire.bank_name);

    formData.append('transfer_date', +new Date(datePickerWireTransfer));
    formData.append('expected_date', +new Date(datePickerWireExp));

    formData.append('type', membershipFormDataWire.type);
    formData.append(
      'transaction_document',
      membershipFormDataWire.transaction_document
    );
    formData.append(
      'transaction_document_2',
      membershipFormDataWire.transaction_document_2
    );
    formData.append(
      'transaction_document_3',
      membershipFormDataWire.transaction_document_3
    );

    formData.append('txHash', membershipFormDataWire.txHash);

    payFeeHandle(formData, membershipFormDataWire, 'wire');
  };

  const submitWireRenewHandle = () => {
    if (
      membershipFormDataWireRenew?.transaction_document_2 ||
      membershipFormDataWireRenew?.transaction_document_3 ||
      membershipFormDataWireRenew?.transaction_document
    ) {
      const formData = new FormData();
      formData.append(
        'transaction_document',
        membershipFormDataWireRenew.transaction_document
      );
      formData.append(
        'transaction_document_2',
        membershipFormDataWireRenew.transaction_document_2
      );
      formData.append(
        'transaction_document_3',
        membershipFormDataWireRenew.transaction_document_3
      );

      formData.append('account_id', membershipFormDataWireRenew.account_id);
      formData.append(
        'transfer_date',
        +new Date(datePickerWireTransfer)
          ? +new Date(datePickerWireTransfer)
          : +new Date()
      );
      formData.append(
        'expected_date',
        +new Date(datePickerWireExp)
          ? +new Date(datePickerWireExp)
          : +new Date()
      );

      formData.append('type', membershipFormDataWireRenew.type);
      formData.append('bank_type', membershipFormDataWireRenew.bank_type);

      console.log('formData', formData, membershipFormDataWireRenew);

      payFeeRenewHandle(formData, membershipFormDataWireRenew, 'wire');
    } else {
      toast.error('Please upload at least one document');
    }
  };

  useEffect(() => {
    loadPaymenInfo();
    loadPaymenCheckMigratedUser();
    loadWalletInfoMembershipModal();
  }, []);

  useEffect(() => {
    setUpdateTimer(+new Date());
  }, [modal]);

  const [selectedValue, setSelectedValue] = useState('new');

  const handleRadioChange = (value) => {
    if (value == 'balance') {
      setIsCrypto(3);
    }
    if (value == 'new' && isCrypto !== 2 && isCrypto !== 1) {
      setIsCrypto(1);
    }
    setSelectedValue(value);
  };

  const [error, setError] = useState(false);
  useEffect(() => {
    if (membershipFormDataCrypto && membershipFormDataCrypto?.txHash) {
      const pattern = /^0x([A-Fa-f0-9]{64})$/;
      const result = isMatching(membershipFormDataCrypto.txHash, pattern);
      setError(!result);
    }
  }, [membershipFormDataCrypto]);

  const {
    loadWalletInfoDepositModal,

    loadCryptoWallet,
    loadBankWallet,
    allAccountsCrypto,
    allAccountsWire,
    loadAccounts
  } = useContext(WalletContext);

  useEffect(() => {
    loadAccounts();
    loadBankWallet();
  }, []);

  const { flowState } = useContext(PrivateContext);

  if (type === 'yearly') {
    return (
      <>
        {modal && (
          <div className="blur-bg" onClick={() => setModal(false)}></div>
        )}
        {modal && (
          <div className="mod">
            <div className="mod-border">
              <div className="mod-box">
                <p className="mod-box-p">New Payment</p>
                <button className="mod-box-btn" onClick={() => setModal(false)}>
                  X
                </button>
              </div>
              <div className="mod-inside">
                <div
                  style={{
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'row-reverse',
                    gap: '0.5rem'
                  }}
                >
                  <TooltipInfo
                    isInInput={false}
                    type={true}
                    text={
                      <>
                        <div>
                          There are 2 payment methods available for your BOS
                          membership fee.
                          <hr />
                          <hr />
                          {'   '}1. Crypto currency
                          <br />
                          {'   '}2. Bank wire transfer
                          <hr />
                          <hr />
                          <br />
                          <br />
                          Please select your preferred method of payment.
                        </div>
                      </>
                    }
                  />
                  <p
                    style={{
                      marginBottom: '1rem'
                    }}
                  >
                    {flowState ? `$${flowState?.membership_fee}` : '...'} USD
                    Annual BOS Membership Fee
                  </p>
                </div>
                <hr />

                {
                  <>
                    {selectedValue === 'new' && (
                      <div className="mod-inside-flex text-[10px]">
                        <div>
                          <button
                            className={
                              isCrypto === 1
                                ? 'mod-inside-btn-orange pointer'
                                : 'mod-inside-btn pointer'
                            }
                            onClick={() => setIsCrypto(1)}
                          >
                            Crypto
                          </button>

                          <button
                            className={
                              isCrypto === 2
                                ? 'mod-inside-btn-blue pointer'
                                : 'mod-inside-btn pointer'
                            }
                            onClick={() => setIsCrypto(2)}
                          >
                            Bank
                          </button>
                          <button
                            className={
                              isCrypto === 3
                                ? 'mod-inside-btn-blue pointer'
                                : 'mod-inside-btn pointer'
                            }
                            onClick={() => setIsCrypto(3)}
                          >
                            Balance
                          </button>
                        </div>
                        <div className="mod-inside-br"></div>
                      </div>
                    )}

                    {isCrypto === 1 && (
                      <>
                        {allAccountsCrypto &&
                        allAccountsCrypto?.data?.length > 0 ? (
                          <>
                            {allAccountsCrypto &&
                            allAccountsCrypto?.data?.length > 0 ? (
                              <select
                                style={{
                                  background: '#F5F5F5 !important',
                                  border: '0.5px solid #EEEEEE',
                                  width: '100%'
                                }}
                                className="appf-type-selector w-full"
                                onChange={(e) => {
                                  setMembershipFormDataCryptoRenew({
                                    ...membershipFormDataCryptoRenew,
                                    account_id: e.target.value
                                  });
                                }}
                              >
                                <option selected={true}>
                                  {dictionary['CRYPTO-ACCOUNT']}
                                </option>
                                {allAccountsCrypto?.data?.map((type, index) => {
                                  return (
                                    <option key={index} value={type?.id}>
                                      {type?.account_name}
                                    </option>
                                  );
                                })}
                              </select>
                            ) : (
                              <div className="sidebar-content mb-4 bg-slate-50">
                                <MenuItem
                                  title={'Create a new Account'}
                                  path="/financial-account"
                                  icon="icon-account"
                                />
                              </div>
                            )}
                            <DickPickerInputCryptoTransfer />
                            <select
                              style={{
                                background: '#F5F5F5 !important',
                                border: '0.5px solid #EEEEEE',
                                width: '100%'
                              }}
                              className="appf-type-selector"
                              onChange={(e) => {
                                setMembershipFormDataCryptoRenew({
                                  ...membershipFormDataCryptoRenew,
                                  token: e.target.value
                                });
                              }}
                            >
                              <option selected={true}>
                                Select a cryptocurrency
                              </option>
                              <option value={1}>USDT</option>
                              <option value={2}>USDC</option>
                            </select>
                            <input
                              type="text"
                              placeholder="TxHash"
                              className="mod-inside-input"
                              value={membershipFormDataCryptoRenew?.txHash}
                              onChange={(e) => {
                                setMembershipFormDataCryptoRenew({
                                  ...membershipFormDataCryptoRenew,
                                  txHash: e.target.value
                                });
                                const error =
                                  wazardValidation.membershipFeeCryptoPendingProperty(
                                    'txHash',
                                    e.target.value
                                  );
                                setErrorsCryptoFee({
                                  ...errorsCryptoFee,
                                  txHash: error
                                });
                              }}
                            />{' '}
                            {!errorsCryptoFee?.txHash &&
                              membershipFormDataCrypto &&
                              membershipFormDataCrypto.txHash.length > 0 &&
                              error && (
                                <p
                                  style={{
                                    color: 'red',
                                    margin: '0 0 1rem 0',
                                    textAlign: 'center'
                                  }}
                                >
                                  Invalid Tx Hash. <br />
                                  Please double-check and enter a valid Tx Hash.
                                </p>
                              )}
                            {errorsCryptoFee && (
                              <p style={{ color: 'red', margin: '0 0 1rem 0' }}>
                                {errorsCryptoFee?.txHash}
                              </p>
                            )}
                            <div className="mod-img-border">
                              <div className="mod-img-border-parent">
                                {walletInfoMembershipModal &&
                                walletInfoMembershipModal?.crypto?.length > 0 &&
                                walletInfoMembershipModal?.crypto?.[0]
                                  ?.address ? (
                                  <QRCodeSVG
                                    style={{
                                      background: 'white',
                                      padding: '1rem'
                                    }}
                                    value={
                                      walletInfoMembershipModal?.crypto?.[0]
                                        ?.address
                                    }
                                    size={180}
                                    bgColor={'#ffffff'}
                                    fgColor={'#000000'}
                                    level={'L'}
                                    includeMargin={false}
                                  />
                                ) : (
                                  <img
                                    className="mod-img"
                                    src="/images/QRCode.png"
                                    alt="QRCode"
                                  />
                                )}
                              </div>

                              <div className="mod-img-bottom">
                                <div>
                                  <div className="set-wall-title">
                                    Wallet address
                                  </div>
                                  {walletInfoMembershipModal &&
                                  walletInfoMembershipModal?.crypto?.length >
                                    0 &&
                                  walletInfoMembershipModal?.crypto?.[0]
                                    ?.address ? (
                                    <div
                                      className="set-wall-title"
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          walletInfoMembershipModal?.crypto?.[0]
                                            ?.address
                                      }}
                                    ></div>
                                  ) : (
                                    'Loading...'
                                  )}
                                </div>
                                {walletInfoMembershipModal &&
                                walletInfoMembershipModal?.crypto?.length > 0 &&
                                walletInfoMembershipModal?.crypto?.[0]
                                  ?.address ? (
                                  <CopyToClipboard
                                    text={
                                      walletInfoMembershipModal?.crypto?.[0]
                                        ?.address
                                    }
                                    onCopy={() => {
                                      toast.success('Copied Successfully.');
                                    }}
                                  >
                                    <button className="mod-img-bottom-btn">
                                      Copy
                                    </button>
                                  </CopyToClipboard>
                                ) : (
                                  <button
                                    className="mod-img-bottom-btn"
                                    disabled={true}
                                  >
                                    Copy
                                  </button>
                                )}
                                <p
                                  style={{
                                    color: '#E74545',
                                    fontSize: 13,
                                    textAlign: 'left'
                                  }}
                                >
                                  <b>IMPORTANT:</b> BOS only accepts USDC or
                                  USDT (ETH) transactions made on the ERC-20
                                  network. ( DO NOT send Ethereum! ) Only
                                  deposits from decentralized wallets are
                                  accepted. These wallets include Ultimo,
                                  Metamask, Trust Wallet, Exodus, Atomic,
                                  Trezor/Ledger. You may use any other wallet as
                                  long as you are certain it is decentralized.
                                </p>
                              </div>
                            </div>
                          </>
                        ) : (
                          <div className="sidebar-content mb-4 bg-slate-50">
                            <MenuItem
                              title={'Create a new Account'}
                              path="/financial-account"
                              icon="icon-account"
                            />
                          </div>
                        )}
                      </>
                    )}

                    {isCrypto === 2 && (
                      <>
                        {allAccountsWire &&
                        allAccountsWire?.data?.length > 0 ? (
                          <>
                            <Flex
                              direction="col"
                              gap="1"
                              x="center"
                              marginB="1"
                            >
                              {/* <p>
                                Account Name:{' '}
                                <b>
                                  {walletInfoMembershipModal &&
                                  walletInfoMembershipModal?.bank?.length > 0 &&
                                  walletInfoMembershipModal?.bank?.[0]
                                    ? walletInfoMembershipModal?.bank?.[0]?.name
                                    : 'Loading...'}
                                </b>
                              </p> */}
                              <p style={{ textAlign: 'center' }}>
                                {/* Account Number{' '} */}
                                <b>
                                  {walletInfoMembershipModal &&
                                  walletInfoMembershipModal?.bankInfo?.length >
                                    0 &&
                                  walletInfoMembershipModal?.bankInfo?.[0]
                                    ?.bank_info ? (
                                    <>
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            walletInfoMembershipModal
                                              ?.bankInfo?.[0]?.bank_info
                                        }}
                                      ></div>
                                      <hr className="pb-6 " />
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            walletInfoMembershipModal?.bank?.[0]
                                              ?.address
                                        }}
                                      ></div>
                                    </>
                                  ) : (
                                    'Loading...'
                                  )}
                                </b>
                              </p>
                            </Flex>

                            {allAccountsWire &&
                            allAccountsWire?.data?.length > 0 ? (
                              <select
                                style={{
                                  background: '#F5F5F5 !important',
                                  border: '0.5px solid #EEEEEE',
                                  width: '100%'
                                }}
                                className="appf-type-selector w-full"
                                onChange={(e) => {
                                  setMembershipFormDataWireRenew({
                                    ...membershipFormDataWireRenew,
                                    account_id: e.target.value
                                  });
                                }}
                              >
                                <option selected={true}>
                                  {dictionary['BANK-ACCOUNT']}
                                </option>
                                {allAccountsWire?.data?.map((type, index) => {
                                  return (
                                    <option key={index} value={type?.id}>
                                      {type?.account_name}
                                    </option>
                                  );
                                })}
                              </select>
                            ) : (
                              <div className="sidebar-content mb-4 bg-slate-50">
                                <MenuItem
                                  title={'Create a new Account'}
                                  path="/financial-account"
                                  icon="icon-account"
                                />
                              </div>
                            )}

                            <select
                              style={{
                                background: '#F5F5F5 !important',
                                border: '0.5px solid #EEEEEE',
                                width: '100%'
                              }}
                              className="appf-type-selector"
                              placeholder="BankType"
                              onChange={(e) => {
                                setMembershipFormDataWireRenew({
                                  ...membershipFormDataWireRenew,
                                  bank_type: e.target.value
                                });
                              }}
                            >
                              {/* <option value={0} selected={true}>
                                      {modalDictionary['MODAL-title51']}
                                    </option> */}
                              {/* <option value={1}>IMBL</option> */}
                              {/* <option value={2}>TCC</option> */}
                              {/* <option value={3}>Caye Bank</option> */}
                              {/* <option value={4}>Chase Bank</option> */}
                              <option value={6}>JP Morgan Chase Bank</option>
                              {/* <option value={5}>Hamilton Bank</option> */}
                            </select>

                            {/* <input
                                          type="text"
                                          placeholder="Transfer date"
                                          className="mod-inside-input"
                                          onChange={(e) => setMembershipFormDataWire({ ...membershipFormDataWire, transfer_date: e.target.value })}
                                      />{' '} */}
                            <DickPickerInputWireTransfer />
                            {/* <input
                                          type="text"
                                          placeholder="Expected clearing date"
                                          className="mod-inside-input"
                                          onChange={(e) => setMembershipFormDataWire({ ...membershipFormDataWire, expected_date: e.target.value })}
                                      />{' '} */}
                            <DickPickerInputWireExp />
                            <div className="mod-upload">
                              <Flex direction={'col'} gap={2} y={'center'}>
                                <label className="mod-upload-label">
                                  Upload bank transaction receipt:
                                </label>
                                <input
                                  type="file"
                                  id="file-input"
                                  name="file-input"
                                  onChange={(e) => {
                                    setMembershipFormDataWireRenew({
                                      ...membershipFormDataWireRenew,
                                      transaction_document: e.target.files?.[0]
                                    });
                                    const error =
                                      wazardValidation.membershipFeeWirePendingProperty(
                                        'transaction_document',
                                        e.target.value
                                      );
                                    setErrorsWireFee({
                                      ...errorsWireFee,
                                      transaction_document: error
                                    });
                                  }}
                                />
                                {errorsWireFee && (
                                  <p
                                    style={{
                                      color: 'red',
                                      margin: '0 0 1rem 0'
                                    }}
                                  >
                                    {errorsWireFee?.transaction_document}
                                  </p>
                                )}

                                {/* Next */}

                                <label className="mod-upload-label">
                                  Upload bank transaction receipt:
                                </label>
                                <input
                                  type="file"
                                  id="file-input"
                                  name="file-input"
                                  onChange={(e) => {
                                    setMembershipFormDataWireRenew({
                                      ...membershipFormDataWireRenew,
                                      transaction_document_2:
                                        e.target.files?.[0]
                                    });
                                    const error =
                                      wazardValidation.membershipFeeWirePendingProperty(
                                        'transaction_document_2',
                                        e.target.value
                                      );
                                    setErrorsWireFee({
                                      ...errorsWireFee,
                                      transaction_document_2: error
                                    });
                                  }}
                                />
                                {errorsWireFee && (
                                  <p
                                    style={{
                                      color: 'red',
                                      margin: '0 0 1rem 0'
                                    }}
                                  >
                                    {errorsWireFee?.transaction_document_2}
                                  </p>
                                )}

                                {/* Next */}

                                <label className="mod-upload-label">
                                  Upload bank transaction receipt:
                                </label>
                                <input
                                  type="file"
                                  id="file-input"
                                  name="file-input"
                                  onChange={(e) => {
                                    setMembershipFormDataWireRenew({
                                      ...membershipFormDataWireRenew,
                                      transaction_document_3:
                                        e.target.files?.[0]
                                    });
                                    const error =
                                      wazardValidation.membershipFeeWirePendingProperty(
                                        'transaction_document_3',
                                        e.target.value
                                      );
                                    setErrorsWireFee({
                                      ...errorsWireFee,
                                      transaction_document_3: error
                                    });
                                  }}
                                />
                                {errorsWireFee && (
                                  <p
                                    style={{
                                      color: 'red',
                                      margin: '0 0 1rem 0'
                                    }}
                                  >
                                    {errorsWireFee?.transaction_document_3}
                                  </p>
                                )}
                              </Flex>
                            </div>
                          </>
                        ) : (
                          <div className="sidebar-content mb-4 bg-slate-50">
                            <MenuItem
                              title={'Create a new Account'}
                              path="/financial-account"
                              icon="icon-account"
                            />
                          </div>
                        )}
                      </>
                    )}
                    {isCrypto === 3 && (
                      <>
                        You can renew your annual BOS membership fee using your
                        Main Balance if you have a minimum of{' '}
                        {flowState ? `$${flowState?.membership_fee}` : '...'}{' '}
                        USD. Alternatively, if you have enough funds active in
                        at least one of the Islands facilitated by BOS that meet
                        or exceed the{' '}
                        {flowState ? `$${flowState?.membership_fee}` : '...'}{' '}
                        USD required fee, your Main Balance will show -
                        {flowState ? `$${flowState?.membership_fee}` : '...'}{' '}
                        USD. This balance will be adjusted once new funds are
                        deposited into your Main Balance.
                      </>
                    )}
                  </>
                }
              </div>
              <div className="mod-bottom">
                {isCrypto === 1 ? (
                  <>
                    {allAccountsCrypto &&
                    allAccountsCrypto?.data?.length > 0 ? (
                      <button
                        className="mod-bottom-modal-btn"
                        onClick={() => {
                          if (membershipFormDataCryptoRenew?.token.length > 0) {
                            !isLockedBtnPayFee
                              ? !error && submitCryptoRenewHandle()
                              : null;
                          } else {
                            toast.error('Please select a cryptocurrency.');
                          }
                        }}
                      >
                        Submit
                      </button>
                    ) : null}
                  </>
                ) : null}
                {isCrypto === 2 ? (
                  <>
                    {allAccountsWire && allAccountsWire?.data?.length > 0 ? (
                      <button
                        className="mod-bottom-modal-btn"
                        onClick={() =>
                          !isLockedBtnPayFee ? submitWireRenewHandle() : null
                        }
                      >
                        Submit
                      </button>
                    ) : null}
                  </>
                ) : null}
                {isCrypto === 3 ? (
                  <button
                    className="mod-bottom-modal-btn"
                    onClick={() =>
                      !isLockedBtnPayFee ? submitBOSRenewHandle() : null
                    }
                  >
                    Submit
                  </button>
                ) : null}
              </div>
            </div>
          </div>
        )}
      </>
    );
  } else {
    return (
      <>
        {modal && (
          <div className="blur-bg" onClick={() => setModal(false)}></div>
        )}
        {modal && (
          <div className="mod">
            <div className="mod-border">
              <div className="mod-box">
                <p className="mod-box-p">New Payment</p>
                <button className="mod-box-btn" onClick={() => setModal(false)}>
                  X
                </button>
              </div>
              <div className="mod-inside">
                <div
                  style={{
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'row-reverse',
                    gap: '0.5rem'
                  }}
                >
                  <TooltipInfo
                    isInInput={false}
                    type={true}
                    text={
                      <>
                        <div>
                          There are 2 payment methods available for your BOS
                          membership fee.
                          <hr />
                          <hr />
                          {'   '}1. Crypto currency
                          <br />
                          {'   '}2. Bank wire transfer
                          <hr />
                          <hr />
                          <br />
                          <br />
                          Please select your preferred method of payment.
                        </div>
                      </>
                    }
                  />
                  <p>
                    {flowState ? `$${flowState?.membership_fee}` : '...'} USD
                    Annual BOS Membership Fee
                  </p>
                </div>
                <hr />

                {/* {paymentCheckUser && (
                  <RadioGroup
                    className="custom-radio-group"
                    onChange={handleRadioChange}
                    value={selectedValue}
                  >
                    <RadioButton pointColor="#0e608b" value="balance">BOS Main Balance</RadioButton>
                    <Flex direction="col" gap="1" x="left" marginB="1">
                      <p
                        style={{
                          textAlign: 'left',
                          marginLeft: '2rem',
                          marginBottom: '1rem'
                        }}
                      >
                        By selecting the above option, the amount will be deducted from your <b>BOS Main Balance</b>.
                      </p>
                    </Flex>

                    <RadioButton pointColor="#0e608b" value="new">New Payment</RadioButton>
                  </RadioGroup>
                )} */}
                {
                  <>
                    {selectedValue === 'new' && (
                      <div className="mod-inside-flex">
                        <div>
                          <button
                            className={
                              isCrypto === 1
                                ? 'mod-inside-btn-orange pointer'
                                : 'mod-inside-btn pointer'
                            }
                            onClick={() => setIsCrypto(1)}
                          >
                            Crypto
                          </button>

                          <button
                            className={
                              isCrypto === 2
                                ? 'mod-inside-btn-blue pointer'
                                : 'mod-inside-btn pointer'
                            }
                            onClick={() => setIsCrypto(2)}
                          >
                            Bank
                          </button>

                          {paymentCheckUser && (
                            <button
                              className={
                                isCrypto === 3
                                  ? 'mod-inside-btn-blue pointer'
                                  : 'mod-inside-btn pointer'
                              }
                              onClick={() => setIsCrypto(3)}
                            >
                              Balance
                            </button>
                          )}
                        </div>
                        <div className="mod-inside-br"></div>
                      </div>
                    )}

                    {isCrypto === 1 && (
                      <>
                        {/* <input
                                      type="text"
                                      placeholder="Transfer date"
                                      className="mod-inside-input"
                                      onChange={(e) => setMembershipFormDataCrypto({ ...membershipFormDataCrypto, transfer_date: e.target.value })}
                                  />{' '} */}
                        <DickPickerInputCryptoTransfer />
                        <select
                          style={{
                            background: '#F5F5F5 !important',
                            border: '0.5px solid #EEEEEE',
                            width: '100%'
                          }}
                          className="appf-type-selector"
                          onChange={(e) => {
                            setMembershipFormDataCrypto({
                              ...membershipFormDataCrypto,
                              token: e.target.value
                            });
                          }}
                        >
                          <option selected={true}>
                            Select a cryptocurrency
                          </option>
                          <option value={1}>USDT</option>
                          <option value={2}>USDC</option>
                        </select>
                        <input
                          type="text"
                          placeholder="TxHash"
                          className="mod-inside-input"
                          value={membershipFormDataCrypto?.txHash}
                          onChange={(e) => {
                            setMembershipFormDataCrypto({
                              ...membershipFormDataCrypto,
                              txHash: e.target.value
                            });
                            const error =
                              wazardValidation.membershipFeeCryptoPendingProperty(
                                'txHash',
                                e.target.value
                              );
                            setErrorsCryptoFee({
                              ...errorsCryptoFee,
                              txHash: error
                            });
                          }}
                        />{' '}
                        {!errorsCryptoFee?.txHash &&
                          membershipFormDataCrypto &&
                          membershipFormDataCrypto.txHash.length > 0 &&
                          error && (
                            <p
                              style={{
                                color: 'red',
                                margin: '0 0 1rem 0',
                                textAlign: 'center'
                              }}
                            >
                              Invalid Tx Hash. <br />
                              Please double-check and enter a valid Tx Hash.
                            </p>
                          )}
                        {errorsCryptoFee && (
                          <p style={{ color: 'red', margin: '0 0 1rem 0' }}>
                            {errorsCryptoFee?.txHash}
                          </p>
                        )}
                        <div className="mod-img-border">
                          <div className="mod-img-border-parent">
                            {walletInfoMembershipModal &&
                            walletInfoMembershipModal?.crypto?.length > 0 &&
                            walletInfoMembershipModal?.crypto?.[0]?.address ? (
                              <QRCodeSVG
                                style={{
                                  background: 'white',
                                  padding: '1rem'
                                }}
                                value={
                                  walletInfoMembershipModal?.crypto?.[0]
                                    ?.address
                                }
                                size={180}
                                bgColor={'#ffffff'}
                                fgColor={'#000000'}
                                level={'L'}
                                includeMargin={false}
                              />
                            ) : (
                              <img
                                className="mod-img"
                                src="/images/QRCode.png"
                                alt="QRCode"
                              />
                            )}
                          </div>

                          <div className="mod-img-bottom">
                            <div>
                              <div className="set-wall-title">
                                Wallet address
                              </div>
                              {walletInfoMembershipModal &&
                              walletInfoMembershipModal?.crypto?.length > 0 &&
                              walletInfoMembershipModal?.crypto?.[0]
                                ?.address ? (
                                <div
                                  className="set-wall-title"
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      walletInfoMembershipModal?.crypto?.[0]
                                        ?.address
                                  }}
                                ></div>
                              ) : (
                                'Loading...'
                              )}
                            </div>
                            {walletInfoMembershipModal &&
                            walletInfoMembershipModal?.crypto?.length > 0 &&
                            walletInfoMembershipModal?.crypto?.[0]?.address ? (
                              <CopyToClipboard
                                text={
                                  walletInfoMembershipModal?.crypto?.[0]
                                    ?.address
                                }
                                onCopy={() => {
                                  toast.success('Copied Successfully.');
                                }}
                              >
                                <button className="mod-img-bottom-btn">
                                  Copy
                                </button>
                              </CopyToClipboard>
                            ) : (
                              <button
                                className="mod-img-bottom-btn"
                                disabled={true}
                              >
                                Copy
                              </button>
                            )}
                            <p
                              style={{
                                color: '#E74545',
                                fontSize: 13,
                                textAlign: 'left'
                              }}
                            >
                              <b>IMPORTANT:</b> BOS only accepts USDC or USDT
                              (ETH) transactions made on the ERC-20 network. (
                              DO NOT send Ethereum! ) Only deposits from
                              decentralized wallets are accepted. These wallets
                              include Ultimo, Metamask, Trust Wallet, Exodus,
                              Atomic, Trezor/Ledger. You may use any other
                              wallet as long as you are certain it is
                              decentralized.
                            </p>
                          </div>
                        </div>
                      </>
                    )}

                    {isCrypto === 2 && (
                      <>
                        <Flex direction="col" gap="1" x="center" marginB="1">
                          {/* <p>
                            Account Name:{' '}
                            <b>
                              {walletInfoMembershipModal &&
                              walletInfoMembershipModal?.bank?.length > 0 &&
                              walletInfoMembershipModal?.bank?.[0]
                                ? walletInfoMembershipModal?.bank?.[0]?.name
                                : 'Loading...'}
                            </b>
                          </p> */}
                          <p style={{ textAlign: 'center' }}>
                            {/* Account Number{' '} */}
                            <b>
                              {walletInfoMembershipModal &&
                              walletInfoMembershipModal?.bankInfoMembership
                                ?.length > 0 &&
                              walletInfoMembershipModal?.bankInfoMembership?.[0]
                                ?.bank_membership_info ? (
                                <>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        walletInfoMembershipModal
                                          ?.bankInfoMembership?.[0]
                                          ?.bank_membership_info
                                    }}
                                  ></div>
                                  <hr className="pb-6 " />
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        walletInfoMembershipModal?.bank?.[0]
                                          ?.address
                                    }}
                                  ></div>
                                </>
                              ) : (
                                'Loading...'
                              )}
                            </b>
                          </p>
                        </Flex>
                        <input
                          type="text"
                          placeholder="Name of the account"
                          className="mod-inside-input"
                          value={membershipFormDataWire?.account_name}
                          onChange={(e) => {
                            setMembershipFormDataWire({
                              ...membershipFormDataWire,
                              account_name: e.target.value
                            });
                            const error =
                              wazardValidation.membershipFeeWirePendingProperty(
                                'account_name',
                                e.target.value
                              );
                            setErrorsWireFee({
                              ...errorsWireFee,
                              account_name: error
                            });
                          }}
                        />
                        {errorsWireFee && (
                          <p style={{ color: 'red', margin: '0 0 1rem 0' }}>
                            {errorsWireFee?.account_name}
                          </p>
                        )}
                        <input
                          type="text"
                          placeholder="Account number"
                          className="mod-inside-input"
                          onChange={(e) => {
                            setMembershipFormDataWire({
                              ...membershipFormDataWire,
                              account_number: e.target.value
                            });
                            const error =
                              wazardValidation.membershipFeeWirePendingProperty(
                                'account_number',
                                e.target.value
                              );
                            setErrorsWireFee({
                              ...errorsWireFee,
                              account_number: error
                            });
                          }}
                        />
                        {errorsWireFee && (
                          <p style={{ color: 'red', margin: '0 0 1rem 0' }}>
                            {errorsWireFee?.account_number}
                          </p>
                        )}
                        <input
                          type="text"
                          placeholder="Bank name"
                          className="mod-inside-input"
                          onChange={(e) => {
                            setMembershipFormDataWire({
                              ...membershipFormDataWire,
                              bank_name: e.target.value
                            });
                            const error =
                              wazardValidation.membershipFeeWirePendingProperty(
                                'bank_name',
                                e.target.value
                              );
                            setErrorsWireFee({
                              ...errorsWireFee,
                              bank_name: error
                            });
                          }}
                        />
                        {errorsWireFee && (
                          <p style={{ color: 'red', margin: '0 0 1rem 0' }}>
                            {errorsWireFee?.bank_name}
                          </p>
                        )}
                        {/* <input
                          type="text"
                          placeholder="Transfer date"
                          className="mod-inside-input"
                          onChange={(e) =>
                            setMembershipFormDataWire({
                              ...membershipFormDataWire,
                              transfer_date: e.target.value
                            })
                          }
                        />{' '} */}
                        <DickPickerInputWireTransfer />
                        {/* <input
                          type="text"
                          placeholder="Expected clearing date"
                          className="mod-inside-input"
                          onChange={(e) =>
                            setMembershipFormDataWire({
                              ...membershipFormDataWire,
                              expected_date: e.target.value
                            })
                          }
                        />{' '} */}
                        <DickPickerInputWireExp />
                        <div className="mod-upload">
                          <Flex direction={'col'} gap={2} y={'center'}>
                            <label className="mod-upload-label">
                              Upload bank transaction receipt:
                            </label>
                            <input
                              type="file"
                              id="file-input"
                              name="file-input"
                              onChange={(e) => {
                                setMembershipFormDataWire({
                                  ...membershipFormDataWire,
                                  transaction_document: e.target.files?.[0]
                                });
                                const error =
                                  wazardValidation.membershipFeeWirePendingProperty(
                                    'transaction_document',
                                    e.target.value
                                  );
                                setErrorsWireFee({
                                  ...errorsWireFee,
                                  transaction_document: error
                                });
                              }}
                            />
                            {errorsWireFee && (
                              <p style={{ color: 'red', margin: '0 0 1rem 0' }}>
                                {errorsWireFee?.transaction_document}
                              </p>
                            )}

                            {/* Next */}

                            <label className="mod-upload-label">
                              Upload bank transaction receipt:
                            </label>
                            <input
                              type="file"
                              id="file-input"
                              name="file-input"
                              onChange={(e) => {
                                setMembershipFormDataWire({
                                  ...membershipFormDataWire,
                                  transaction_document_2: e.target.files?.[0]
                                });
                                const error =
                                  wazardValidation.membershipFeeWirePendingProperty(
                                    'transaction_document_2',
                                    e.target.value
                                  );
                                setErrorsWireFee({
                                  ...errorsWireFee,
                                  transaction_document_2: error
                                });
                              }}
                            />
                            {errorsWireFee && (
                              <p style={{ color: 'red', margin: '0 0 1rem 0' }}>
                                {errorsWireFee?.transaction_document_2}
                              </p>
                            )}

                            {/* Next */}

                            <label className="mod-upload-label">
                              Upload bank transaction receipt:
                            </label>
                            <input
                              type="file"
                              id="file-input"
                              name="file-input"
                              onChange={(e) => {
                                setMembershipFormDataWire({
                                  ...membershipFormDataWire,
                                  transaction_document_3: e.target.files?.[0]
                                });
                                const error =
                                  wazardValidation.membershipFeeWirePendingProperty(
                                    'transaction_document_3',
                                    e.target.value
                                  );
                                setErrorsWireFee({
                                  ...errorsWireFee,
                                  transaction_document_3: error
                                });
                              }}
                            />
                            {errorsWireFee && (
                              <p style={{ color: 'red', margin: '0 0 1rem 0' }}>
                                {errorsWireFee?.transaction_document_3}
                              </p>
                            )}
                          </Flex>
                        </div>
                      </>
                    )}

                    {isCrypto === 3 && (
                      <p>
                        You have received the exception to pay your annual BOS
                        membership fee using your Main Balance, your Main
                        Balance will show -
                        {flowState ? `$${flowState?.membership_fee}` : '...'}{' '}
                        USD upon selecting this method. This balance will be
                        adjusted once new funds are deposited into your Main
                        Balance.
                      </p>
                    )}
                  </>
                }
              </div>
              <div className="mod-bottom">
                {isCrypto === 1 ? (
                  <button
                    className={`mod-bottom-modal-btn ${
                      !(
                        membershipFormDataCrypto?.token.length > 0 &&
                        membershipFormDataCrypto?.txHash?.length > 29
                      ) && 'opacity-40 cursor-not-allowed'
                    }`}
                    onClick={() => {
                      if (
                        membershipFormDataCrypto?.token.length > 0 &&
                        membershipFormDataCrypto?.txHash?.length > 29
                      ) {
                        !isLockedBtnPayFee
                          ? !error && submitCryptoHandle()
                          : null;
                      } else {
                        toast.error('Please select a cryptocurrency.');
                      }
                    }}
                  >
                    Submit
                  </button>
                ) : null}
                {isCrypto === 2 ? (
                  <button
                    className={`mod-bottom-modal-btn ${
                      !(
                        membershipFormDataWire?.account_name?.length > 0 &&
                        Number(membershipFormDataWire?.account_number) > 0 &&
                        membershipFormDataWire?.bank_name?.length > 0
                      ) && 'opacity-40 cursor-not-allowed'
                    }`}
                    onClick={() =>
                      !(
                        membershipFormDataWire?.account_name?.length > 0 &&
                        Number(membershipFormDataWire?.account_number) > 0 &&
                        membershipFormDataWire?.bank_name?.length > 0
                      )
                        ? null
                        : submitWireHandle()
                    }
                  >
                    Submit
                  </button>
                ) : null}
                {isCrypto === 3 ? (
                  <button
                    className="mod-bottom-modal-btn"
                    onClick={() =>
                      !isLockedBtnPayFee ? submitBOSHandle() : null
                    }
                  >
                    Submit
                  </button>
                ) : null}
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
};

export default Modal;
